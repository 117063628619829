.hand-picked-section {
    .hand-picked-swiper {
        padding-right: 100px;
    }
    .hand-picked-slide {
        transition: all 0.6s ease 0s;
        a {
            width: 100%;
            figure {
                border-radius: 14px;
                overflow: hidden;
                position: relative;
                width: 100%;
                // height: 533px;
                img {
                    width: 100%;
                    object-fit: cover;
                }
                figcaption {
                    position: absolute;
                    inset: 0;
                    background: linear-gradient(
                        180deg,
                        rgba(61, 65, 82, 0) 17.71%,
                        #3d4152 100%
                    );
                    h2 {
                        background: url("../../../public/svg/common/brush.svg") no-repeat
                            center;
                        min-height: 60px;
                        color: white;
                        font-size: 2.375rem;
                        min-width: 152px;
                        @media only screen and (max-width: 1023px) {
                            font-size: 1.5rem;
                            font-weight: normal;
                            min-width: 120px;
                            max-width: 120px;
                            background-size: contain;
                        }
                    }
                }
            }
            @media only screen and (max-width: 1023px) {
                margin-left: initial;
                margin-right: initial;
            }
        }
    }
}
