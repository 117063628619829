.budget-friendly-section {
    background-image: url('../../../public/svg/home/budget-friendly/line-location.svg');
    background-position: left 35% bottom 10%;
    background-repeat: no-repeat;
    .budget-friendly-container {
        background-image: url('../../../public/svg/home/budget-friendly/trally.svg'), url('../../../public/svg/home/budget-friendly/tree.svg');
        background-position: left 235px top 40px, right 235px top 30px;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1279px) {
            background-position: left 135px top 40px, right 135px top 30px;
        }
        @media only screen and (max-width: 1023px) {
            background-position: left 35px top 40px, right 35px top 30px;
        }
        @media only screen and (max-width: 767px) {
            background-image: initial;
        }
    }
    .budget-friendly-block {
        a {
            figure {
                border-radius: 14px;
                overflow: hidden;
                position: relative;
                height: 100%;
                .redirect,
                figcaption {
                    position: absolute;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .redirect {
                    right: 0;
                    top: 0;
                    opacity: 0;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }
                figcaption {
                    inset: 0;
                    background: linear-gradient(180deg, rgba(61, 65, 82, 0.00) 17.71%, #3D4152 100%);
                    border-radius: 14px;
                    h2 {
                        background: url('../../../public/svg/common/brush.svg') no-repeat center;
                        min-height: 60px;
                        color: white;
                        font-size: 2.375rem;
                        min-width: 152px;
                        @media only screen and (max-width: 1023px) {
                            font-size: 1.5rem;
                            font-weight: normal;
                            min-width: 120px;
                            max-width: 120px;
                            background-size: contain;
                        }
                    }
                }
            }
            &:nth-child(2) {
                width: 85%;
                @media only screen and (max-width: 1023px) {
                    width: 100%;
                }
            }
            &:hover {
                .redirect {
                    right: 12px;
                    top: 12px;
                    opacity: 1;
                }
            }
            &.budget-friendly-two {
                @media only screen and (max-width: 1023px) {
                    max-height: 280px;
                }
            }
            @media only screen and (max-width: 1023px) {
                margin-left: initial;
                margin-right: initial;
                width: 100%;
            }
            @media only screen and (max-width: 767px) {
                max-height: 280px;
                height: 280px;
            }
            @media only screen and (max-width: 639px) {
                max-height: 180px;
                height: 180px;
            }
        }
    }
    @media only screen and (max-width: 1535px) {
        background-position: left 25% bottom 10%;
    }
    @media only screen and (max-width: 1023px) {
        background-position: left 38% bottom 5%;
    }
    @media only screen and (max-width: 767px) {
        background-image: initial;
    }
}